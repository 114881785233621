/**
 * index.jsx
 * 
 * @file Index page of the website.
 * @author Robin Walter <hello@robinwalter.me>
 */

import clsx from 'clsx'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

// internal imports
import BackgroundWallpaperSVGA from '../assets/images/Wallpaper/wallpaper-svga-dark.png'
import BackgroundWallpaperXGA from '../assets/images/Wallpaper/wallpaper-xga-dark.png'
import BackgroundWallpaperWXGA720p from '../assets/images/Wallpaper/wallpaper-wxga-720p-dark.png'
import BackgroundWallpaperHDP from '../assets/images/Wallpaper/wallpaper-hd+-dark.png'
import BackgroundWallpaperFHD1080p from '../assets/images/Wallpaper/wallpaper-fhd-1080p-dark.png'
import BackgroundWallpaperWQHD from '../assets/images/Wallpaper/wallpaper-wqhd-dark.png'
import BackgroundWallpaperQFHD from '../assets/images/Wallpaper/wallpaper-qfhd-dark.png'
import { Layout } from '../components/Layout'

/**
 * This Component represents the index "home" page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const IndexPage = ( { data } ) => {

    return (
        <>
            <GatsbySeo title="Startseite" />
            <Layout sticky={ {
                animation: 'uk-animation-slide-top',
                top: '#hero'
            } }>
                <div
                    className={ clsx( 'uk-section-secondary' ) }
                    rw-header-transparent="dark"
                    rw-header-transparent-placeholder=""
                    uk-scrollspy={ JSON.stringify( {
                        cls: 'uk-animation-slide-top',
                        delay: false,
                        target: '[uk-scrollspy-class]'
                    } ) }
                    id="hero">
                    <div
                        className={ clsx(
                            'uk-background-norepeat',
                            'uk-background-contain',
                            'uk-background-center-center',
                            'uk-section',
                            'uk-section-large',
                            'uk-flex',
                            'uk-flex-bottom',
                            'uk-padding-remove-bottom'
                        ) }
                        data-sizes="100vw"
                        data-src={ BackgroundWallpaperQFHD }
                        data-srcset={ `
                            ${ BackgroundWallpaperSVGA } 800w,
                            ${ BackgroundWallpaperXGA } 1024w,
                            ${ BackgroundWallpaperWXGA720p } 1280w,
                            ${ BackgroundWallpaperHDP } 1600w,
                            ${ BackgroundWallpaperFHD1080p } 1920w,
                            ${ BackgroundWallpaperWQHD } 2560w,
                            ${ BackgroundWallpaperQFHD } 3840w
                        ` }
                        uk-height-viewport={ JSON.stringify( {
                            'offset-top': true
                        } ) }
                        uk-img="">

                        <div className={ clsx( 'uk-width-1-1' ) }>
                            <div className={ clsx( 'uk-container' ) }>

                                <div
                                    className={ clsx( 'uk-grid-margin', 'uk-grid-stack' ) }
                                    uk-grid="">
                                    <div
                                        className={ clsx( 'uk-flex-auto', 'uk-width-1-1@m', 'uk-first-column', 'uk-text-center' ) }>

                                        <a href="#ueber-mich" uk-scroll=""><Icon icon={ faAngleDoubleDown } id="hero-angle-double-down" size="2x" /></a>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className={ clsx( 'uk-section-primary', 'uk-section-overlap', 'uk-section', 'uk-section-large' ) }
                    id="ueber-mich"
                    uk-scrollspy={ JSON.stringify( {
                        cls: 'uk-animation-slide-top-medium',
                        delay: false,
                        target: '[uk-scrollspy-class]'
                    } ) }>
                    <div className={ clsx( 'uk-container' ) }>

                        <div
                            className={ clsx( 'uk-grid-margin', 'uk-grid-stack' ) }
                            uk-grid="">
                            <div
                                className={ clsx( 'uk-flex-auto', 'uk-width-1-1@m', 'uk-first-column' ) }>

                                <h4
                                    className={ clsx(
                                        'uk-text-center',
                                        'uk-scrollspy-inview',
                                        'uk-animation-slide-top-medium'
                                    ) }
                                    uk-scrollspy-class="">
                                    Über mich
                                </h4>

                            </div>
                        </div>
                        <div
                            className={ clsx( 'uk-grid-large', 'uk-margin-large' ) }
                            uk-grid="">
                            <div
                                className={ clsx( 'uk-grid-item-match', 'uk-flex-middle', 'uk-width-expand@m', 'uk-first-column' ) }>

                                <div className={ clsx( 'uk-panel', 'uk-width-1-1' ) }>
                                    <Img alt="Ein Portrait von mir." fluid={ data.portrait.childImageSharp.fluid } uk-img="" />
                                </div>

                            </div>
                            <div
                                className={ clsx( 'uk-grid-item-match', 'uk-flex-middle', 'uk-width-expand@m' ) }>

                                <h1
                                    className={ clsx(
                                        'uk-margin-small',
                                        'uk-text-left@m',
                                        'uk-text-center',
                                        'uk-scrollspy-inview',
                                        'uk-animation-slide-left-medium'
                                    ) }
                                    uk-scrollspy-class="">
                                    Hey
                                </h1>
                                <div className={ clsx(
                                    'uk-text-large',
                                    'uk-text-primary',
                                    'uk-margin-small',
                                    'uk-text-left@m',
                                    'uk-text-center',
                                    'uk-scrollspy-inview',
                                    'uk-animation-slide-left-medium'
                                ) }>... und herzlich Willkommen auf meiner Website.</div>
                                <div className={ clsx(
                                    'uk-margin-medium',
                                    'uk-margin-small',
                                    'uk-text-left@m',
                                    'uk-text-center',
                                    'uk-scrollspy-inview',
                                    'uk-animation-slide-left-medium'
                                ) }>Mein Name ist Robin Walter. Ich studiere Elektrotechnik an der FH Südwestfalen in Hagen und in meiner Freizeit arbeite ich gerne an verschiedenen Projekten, die ich hier ganz gerne vorstellen würde.</div>

                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className={ clsx( 'uk-section-muted', 'uk-section-overlap', 'uk-section', 'uk-section-large' ) }
                    id="projekte"
                    uk-height-viewport={ JSON.stringify( {
                        'offset-top': true
                    } ) }
                    uk-scrollspy={ JSON.stringify( {
                        cls: 'uk-animation-slide-top-medium',
                        delay: false,
                        target: '[uk-scrollspy-class]'
                    } ) }>
                    <div className={ clsx( 'uk-container' ) }>

                        <div
                            className={ clsx( 'uk-grid-margin', 'uk-grid', 'uk-grid-stack' ) }
                            uk-grid="">
                            <div
                                className={ clsx( 'uk-flex-auto', 'uk-width-1-1@m', 'uk-first-column' ) }>

                                <h1
                                    className={ clsx(
                                        'uk-margin-small',
                                        'uk-text-center',
                                        'uk-scrollspy-inview',
                                        'uk-animation-slide-top-medium'
                                    ) }
                                    uk-scrollspy-class="">
                                    Projekte
                                </h1>

                            </div>
                            <div
                                className={ clsx( 'uk-flex-auto', 'uk-width-1-1@m' ) }
                                uk-slider={ JSON.stringify( {
                                    center: true
                                } ) }>
                                <div
                                    className={ clsx( 'uk-position-relative', 'uk-visible-toggle', 'uk-dark' ) }
                                    tabIndex="-1">

                                    <ul
                                        className={ clsx( 'uk-slider-items', 'uk-child-width-1-2@s', 'uk-grid' ) }>
                                        
                                        <li id="projects-jd">
                                            <div className={ clsx( 'uk-card' ) }>
                                                <div className={ clsx( 'uk-card-media-top', 'uk-padding' ) }>
                                                    <Img alt="Screenshot der Jugend debattiert NRW Website." fluid={ data.screenshotJugendDebattiertNRW.childImageSharp.fluid } />
                                                </div>
                                                <div className={ clsx( 'uk-card-body' ) }>
                                                    <span className={ clsx( 'uk-card-badge', 'uk-label', 'uk-scrollspy-inview', 'uk-animation-slide-right-medium' ) }>Production</span>
                                                    <h3 className={ clsx( 'uk-card-title', 'uk-scrollspy-inview', 'uk-animation-slide-left-medium' ) }>Jugend debattiert NRW</h3>
                                                    <p className={ clsx( 'uk-scrollspy-inview', 'uk-animation-slide-left-medium' ) }>Jugend debattiert NRW ist das offizielle Landesportal des Projekts Jugend debattiert, welches ich zusammen mit meinem ehemaligen Lehrer, dem stellv. Landeskoordinator für Jugend debattiert in NRW, entwickelt habe.</p>
                                                    <p className={ clsx( 'uk-scrollspy-inview', 'uk-animation-slide-left-medium' ) }>Die Website ermöglicht es unter anderem, dass die Anmeldung zu Schulungen online und automatisiert ablaufen kann.</p>
                                                    <p className={ clsx( 'uk-scrollspy-inview', 'uk-animation-slide-left-medium' ) }>Weitere Informationen zu dem Landesportal und dem Projekt finden Sie auf der Website.</p>
                                                </div>
                                                <div className={ clsx( 'uk-card-footer', 'uk-text-right' ) }>
                                                    <a
                                                        className={ clsx( 'uk-button', 'uk-button-link' ) }
                                                        href="https://jugend-debattiert-nrw.de/"
                                                        rel="noopener noreferrer nofollow"
                                                        target="_blank">
                                                        Zur Seite
                                                    </a>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    <a
                                        className={ clsx( 'uk-position-center-left', 'uk-position-small', 'uk-hidden-hover' ) }
                                        href="#"
                                        uk-slidenav-previous=""
                                        uk-slider-item="previous" />
                                    <a
                                        className={ clsx( 'uk-position-center-right', 'uk-position-small', 'uk-hidden-hover' ) }
                                        href="#"
                                        uk-slidenav-next=""
                                        uk-slider-item="next" />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Layout>
        </>
    )

}

export default IndexPage

export const query = graphql`
query {
    portrait: file( relativePath: { eq: "portrait.jpeg" } ) {
        childImageSharp {
            fluid( maxWidth: 600, quality: 100 ) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenshotJugendDebattiertNRW: file( relativePath: { eq: "Projects/Screenshot_jugend-debattiert-nrw.de.png" } ) {
        childImageSharp {
            fluid( maxWidth: 500, quality: 100 ) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
